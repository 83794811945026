const openContactLink = document.querySelector('.open-contact');
const headerContact = document.querySelector('.header__contact');
const headerContactClose = document.querySelector('.header__contact__close');
const body = document.querySelector('body');

openContactLink.addEventListener('click', (event) => {
  event.preventDefault();
  headerContact.setAttribute('data-visible', 'true');
  body.classList.add('no-scroll');
});

headerContactClose.addEventListener('click', () => {
  headerContact.removeAttribute('data-visible');

  const isBurgerMenu = window.innerWidth <= 992;
  !isBurgerMenu && body.classList.remove('no-scroll');
});

const header = document.querySelector('.header');

if (header) {
  const isLight = header.getAttribute('data-origin') === 'light';

  // Update the header based on scroll position
  const updateHeader = () => {
    if (window.scrollY > 100) {
      header.classList.add('header--min');
      if (isLight) {
        header.classList.remove('header--light');
      }
    } else {
      header.classList.remove('header--min');
      if (isLight) {
        header.classList.add('header--light');
      }
    }
  };

  // Call the header update function on initial page load
  updateHeader();

  // Add the scroll event listener that updates the header
  let prevScrollPos = window.pageYOffset;
  window.addEventListener('scroll', () => {
    const currentScrollPos = window.pageYOffset;
    if (currentScrollPos > 300 && currentScrollPos > prevScrollPos) {
      // Scrolling down, hide the header
      header.classList.add('header--hidden');
    } else {
      // Scrolling up or not far enough, show the header
      header.classList.remove('header--hidden');
    }
    prevScrollPos = currentScrollPos;

    // Update the header based on scroll position
    updateHeader();
  });
}
